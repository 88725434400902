import { Link } from "gatsby";
import React from "react";
import RevealFade from "react-reveal/Fade";
import { getImageUrl } from "../../api/Api";
import { translate } from "../../helpers/I18n";
import { Cell, Grid, Row } from "../FoundationGrid";

function MainPageSimpleContentGrid({ page, entries, actions }) {
  return (
    <div className="simple-content-parts-grid">
      <Grid full>
        <Row margin="xy">
          {entries.map((entry, index) => {
            return (
              <Cell sm={24} md={8} key={index}>
                <Entry page={page} entry={entry} actions={actions} />
              </Cell>
            );
          })}
        </Row>
      </Grid>
    </div>
  );
}

const Entry = ({ page, entry, actions }) => {
  return (
    <RevealFade>
      <div className="simple-content-part">
        <button
          className="simple-content-part-image"
          onClick={() => actions.onClick({ entry })}
        >
          <img src={getImageUrl(entry.Image)} alt={entry.Title} />
          <h3>{entry.Title}</h3>
        </button>
        <div className="simple-content-part-content">
          <div className="simple-content-part-excerpt">
            {entry.Excerpt}
            <div>
              <button
                className="simple-content-part-more"
                onClick={() => actions.onClick({ entry })}
              >
                {translate("mainpage.section.more", page.locale)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </RevealFade>
  );
};

export default MainPageSimpleContentGrid;
