import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
//import KeyboardEventHandler from "react-keyboard-event-handler";

function Popup({
  show,
  size,
  noPadding,
  image,
  hideCloseIcon,
  onClose,
  children,
}) {
  return (
    <>
      {/*<KeyboardEventHandler
        handleKeys={["all"]}
        onKeyEvent={(key, event) => {
          if (key === "esc" && !hideCloseIcon && onClose) onClose();
        }}
        handleFocusableElements={true}
      />*/}
      <div className={`__popup-modal ${show ? `is-visible` : `is-hidden`}`}>
        <div className={`__popup size-${size}`}>
          {image && <img src={image} alt="" />}
          {!hideCloseIcon ? <CloseButton onClose={onClose} /> : null}
          <div className={`__popup-content ${noPadding && "nopadding"}`}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

const CloseButton = ({ onClose }) => {
  return (
    <button onClick={onClose} className="__popup-close-button">
      <Icon icon="gg-close" />
    </button>
  );
};

Popup.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.string,
  noPadding: PropTypes.bool,
  image: PropTypes.string,
  onClose: PropTypes.func,
  hideCloseIcon: PropTypes.bool,
};

export default Popup;
